// Allocation functionality.
// 13-04-15
/* globals must be listed below to pass JSHint */
/* globals getRequestPath, searchListForItem, showAlert, renewServerSession, requestTimeout, refreshAllocationPage,
showAjaxLoading, hideAjaxLoading, sortByProperty, searchList, isNumeric, searchArrayForObject, clearAuthorisation,
getBarcodeMapping, getIncidentCodes, getProgramAllocationTypes, getProgramTransactionCode, console, barcodeToData, arrayUnique,
doesProgramPollAllocationChanges, getUserCompany, getScannerLocationCode, getObjectSize, willDownloadExceedAllowance, getSessionUser,
raiseSystemIncident, VesselBarcodeType, getLabels */
// Global variables for application.
// TODO add a required field, populate at start of program and use that globally
// Functions in this file should be changed to use the pickingAllocation object only
// Have to watch the allocation download screen / listing functions have their own methods in that case.
var pickingAllocation = {
  number:0,
  type:"",
  version: 0,
  blend:"",
  index:null,
  location:"",
  age:0,
  disgorgeLines: "",
  updateTime: "1970-01-01T00:00:00.000Z",
  scanned:"",
	scannedTuns: 0,
  casks:[],
	tuns:[],
    pallets: [],
	disgorgeLine:"",
  hasOrderLines:false,
  orderLines:[],
	van:"",
	seal1:"",
	seal2:"",
	seal3:"",
    operationType: "",
    casksPerPallet: 0
  },
 batch_counter,
 allocationSelectElement = '';

function areAllAllocationsDownloaded() {
	"use strict";
	var value = localStorage.getItem("settings/downloadAllAllocations").toUpperCase();
	return (value === "TRUE" || value === "YES");
}

function beginPollingForAllocationChanges() {
	"use strict";
	if (doesProgramPollAllocationChanges()) {
		pollForAllocationChanges();
	}
}

function pollForAllocationChanges() {
	"use strict";
	setTimeout(function(){
		checkAllocationVersion(pickingAllocation.number,function(updated) {
			if(updated) {
				updatePickingAllocation();
			}
			// Repeat
			pollForAllocationChanges();
		});
	}, 60000);
}

/**
 * @description Compares an allocations version with the server, to update if a newer version is available
 * @param {Number} reqno
 * @param callback
 */
function checkAllocationVersion(reqno,callback) {
  "use strict";
  var scanner = parseInt(localStorage.getItem("scannerid")),
      company = getUserCompany();

  $.ajax({
    url: getRequestPath("allocation/version/" + reqno + "?coyno=" + company + "&scanner=" + scanner),
    type: "GET",
    contentType: "application/JSON",
    timeout: requestTimeout,
    xhrFields: {withCredentials: true},
    success: function (data,status,xhr) {
      switch (xhr.status) {
        case 200:
          //good - compare versions
          var allocations = getAllocations();
          var index = searchListForItem(allocations, "requestNumber", reqno);
          if(index !== null) {
            if(data.version > allocations[index].requestVersion) {
              showAlert("Allocation has been changed and will be updated",{type:"info",callback:function() {
                downloadAllocation(reqno,function(successful) {
                  if(successful) {
                    callback(true); //Updated allocation has downloaded and the page needs to be refreshed
                  } else {
                    callback(false); //The allocation did not download so nothing has changed
                  }
                });
              }});
            } else {
              callback(false);//Allocation remains the same - do nothing
            }
          } else {
            callback(false); // should never happen but the allocation is not found on the scanner
          }
          break;
        case 404:
          callback(false); //bad - not an allocation number
          break;
        case 401:
          if (canReattemptAfterLogin()) {
            renewServerSession(function (valid) {
              if (valid) {
                checkAllocationVersion(reqno, callback);
              } else {
                callback(false); // renew session was unsuccessful
              }
            });
          } else {
            callback(false); // Already tried to renew server session
          }
          break;
        default:
          callback(false);//bad - the request failed for some reason
          break;
      }
    },
    error: function () {
        callback(false); //Networking error - silent
    }
  });
}

/**
 * @description Universal function to load allocations from LS.
 */
function getAllocations() {
  "use strict";
  return localStorage.getObject("data/allocations");
}

function setAllocations(allocationData) {
	"use strict";
	localStorage.setObject("data/allocations", allocationData);
}

/**
 * @description Loads allocations from LS which are for use in the current program
 * @returns {Array.<Object>} Allocations
 */
function getProgramAllocations() {
  "use strict";
  var allocations = getAllocations();
  var types = getProgramAllocationTypes();
  return allocations.filter(function (el) {
    return types.indexOf(el.allocationType.toUpperCase()) !== -1;
  });
}

/**
 * @description Looks up allocations stored on the scanner.
 * @returns {string} Returns a comma delimited list of allocation numbers
 */
function getScannerAllocationList() {
  "use strict";
  var allocations = getAllocations();
  var list = "";
  for (var i = 0; i < allocations.length; i++) {
    list += ((list === "") ? "" : ",") + allocations[i].requestNumber;
  }
  return list;
}

/**
 * @description Looks up and lists allocations stored on the scanner
 * @returns {Array} Returns an array of allocation numbers
 */
function listAllocationsOnScanner() {
  "use strict";
  var allocations = getAllocations();
  var numbers = [];
  for (var i=0; i<allocations.length; i++) {
    numbers.push(allocations[i].requestNumber);
  }
  return numbers;
}

/**
 * @description Looks up and lists allocations stored on the scanner for the current program allocation types
 * @returns {Array} Returns an array of allocation numbers
 */
function listProgramAllocationsOnScanner() {
  "use strict";
  var allocations = getProgramAllocations();
  var numbers = [];
  for (var i=0; i<allocations.length; i++) {
    numbers.push(allocations[i].requestNumber);
  }
  return numbers;
}

/**
 * @description Downloads a list of available allocations. Saves an array of allocation Ids.
 * @param {successCallback} callback
 */
function downloadAllocationList(callback) {
  "use strict";
  var scanner = parseInt(localStorage.getItem("scannerid")),
      coyno = getUserCompany(),
      locn = getScannerLocationCode();

  $.ajax({
    url: getRequestPath("allocation?coyno=" + coyno + "&location=" + locn + "&scanner=" + scanner),
    type: "GET",
    contentType: "application/JSON",
    timeout: requestTimeout,
    xhrFields: {
      withCredentials: true
    },
    success: function (data) {
      var availableAllocations = data.allocations.split(",");
      localStorage.setObject("data/exportedAllocations", availableAllocations);
      callback(true);
    },
    error: function (xhr) {
      if (xhr.status === 401 && canReattemptAfterLogin()) {
        renewServerSession(function (valid) {
          if (valid) {
            downloadAllocationList(callback);
          } else {
            callback(false);
          }
        });
      } else if (xhr.status === 404) {
				// No allocations are exported but that could be okay.
        localStorage.setObject("data/exportedAllocations", []);
        callback(true);
      } else {
				// This was a different error, so show the alert before the callback
				showAlert("Cannot download allocation list.", {callback: function() {
					callback(false);
				}});
      }
    }
  });
}

/**
 * @description Retrieves a list of  allocations to update from the server.
 * @param {Boolean} allAllocations If downloading all allocations
 * @param callback Function to run once the list is returned.
 */
function getAllocationListToDownload(allAllocations, callback) {
  "use strict";
  if (allAllocations) {
    // download
    downloadAllocationList(function (success) {
      if (success) {
        var allocations = localStorage.getObject("data/exportedAllocations"),
            list = "";
        for (var i = 0; i < allocations.length; i++) {
          list += ((list === "") ? "" : ",") + allocations[i];
        }
        callback(list);
      } else {
        //list cannot download, abort?
        callback("");
      }
    });
  } else {
    callback(getScannerAllocationList());
  }
}

/**
 * @description Downloads an individual allocation when in the allocations screen.
 * @param {string|number} selected the allocation number to download
 */
function downloadAvailableAllocation(selected) {
  "use strict";
  var no = parseInt(selected);
	$("#availableAllocationPopup-modal").modal("hide");
  downloadAllocation(no, function (successful) {
    if (successful) {
      // close the popup and reload the popup? ALSO reload the page behind to add the new allocation to the list. rebuild table body and html to 'allocationtablebody'
      refreshAllocationPage();
    }
  });
}

/**
 * @description Downloads a single allocation
 * @param {String|Number} reqno
 * @param {successCallbackWithCode} callback
 */
function downloadAllocation(reqno, callback) {
  "use strict";
  var scanner = parseInt(localStorage.getItem("scannerid")),
      coyno = getUserCompany(),
      locn = getScannerLocationCode();

  showAjaxLoading();
  $.ajax({
    url: getRequestPath("allocation/" + reqno + "?coyno=" + coyno + "&location=" + locn + "&scanner=" + scanner),
    type: "GET",
    contentType: "application/JSON",
    timeout: requestTimeout,
    xhrFields: {
      withCredentials: true
    },
    success: function (data, status, xhr) {
      if (xhr.status === 200) {
        hideAjaxLoading();
        saveAllocations(data.allocations, function(saved) {
          callback(saved, 200);
        });
      }
    },
    error: function (xhr) {
      hideAjaxLoading();
      if (xhr.status === 401 && canReattemptAfterLogin()) {
        renewServerSession(function (valid) {
          if (valid) {
            downloadAllocation(reqno, callback);
          } else {
            callback(false, 401);
          }
        });
      } else if (xhr.status === 404) {
        // can we move this error elsewhere. for a program we might want to read Allocation no longer available.
        // showAlert("Invalid Allocation");
        callback(false, 404);
      } else {
        callback(false, xhr.status);
      }
    }
  });
}

/**
 * @description Downloads multiple allocations from the server
 * @param {Object} list Array of allocation Ids to be downloaded.
 * @param {successCallback} callback
 */
function downloadMultipleAllocations(list, callback) {
  "use strict";
  var scanner = parseInt(localStorage.getItem("scannerid")),
      coyno = getUserCompany(),
      locn = getScannerLocationCode();

  var filterdata = {
    scanner: scanner,
    coyno: coyno,
    location: locn,
    allocations: list
  };
  var filterstring = JSON.stringify(filterdata);

  $.ajax({
    url: getRequestPath("allocation"),
    type: "POST",
    contentType: "application/json; charset=UTF-8",
    crossDomain: true,
    timeout:requestTimeout,
    xhrFields: {
      withCredentials: true
    },
    data: filterstring,
    success: function (data, status, xhr) {
      if (xhr.status === 200) {
        if (!jQuery.isEmptyObject(data.allocations)) {
          var currentAllocationList = "";
          for (var a = 0; a < data.allocations.length; a++) {
            currentAllocationList += ((currentAllocationList === "") ? "" : ",") + data.allocations[a].requestNumber;
          }
          removeAllocations(currentAllocationList);
          saveAllocations(data.allocations, function(saved) {
            callback(saved);
          });
        } else {
          //Success, but no allocations exported - remove all. complications here if list was expected and something server side went wrong
          removeAllocations("");
          callback(true);
        }
      } else {
        callback(true);
      }
    },
    error: function (xhr) {
      if (xhr.status === 401 && canReattemptAfterLogin()) {
        renewServerSession(function (valid) {
          if (valid) {
            downloadAllocationList(callback);
          } else {
						showAlert("Allocations cannot be updated.", {callback: function() {
							callback(false);
						}});
          }
        });
      } else {
				showAlert("Allocations cannot be updated.", {callback: function() {
					callback(false);
				}});
      }
    }
  });
}

/**
 * @description Saves received allocations to the scanner. Replaces duplicates.
 * @param {Object} allocations
 * @param {*} callback
 */
function saveAllocations(allocations,callback) {
  "use strict";
  var saveTime = new Date(),
      oldAllocations = getAllocations(),
		  originalSize = getObjectSize(oldAllocations);

  for (var i = 0; i < allocations.length; i++) {

    // find allocation in the scanner
    var oldIndex = searchListForItem(oldAllocations, "requestNumber", allocations[i].requestNumber);
    allocations[i].updateTime = saveTime;
		//If the allocation was on the server, then we update the new set of casks / tuns with scanning statuses
    if (oldIndex !== null) {
			//Process casks
			if (allocations[i].casks) {
				allocations[i].casks = mergeAllocationStatusesByBarcode(oldAllocations[oldIndex].casks,allocations[i].casks);
			}
      //Process tuns
			if (allocations[i].tuns) {
				allocations[i].tuns = mergeAllocationStatusesByTun(oldAllocations[oldIndex].tuns,allocations[i].tuns);
			}
      oldAllocations[oldIndex] = allocations[i];
    } else {
			//Otherwise we simply push the new allocation to the array already on the scanner.
      oldAllocations.push(allocations[i]);
    }
  }
  // sort the array based on request number.
  oldAllocations.sort(sortByProperty("requestNumber"));
  // save allocations back to the local storage.

	console.log("allocations now merged");
	var exceed = willDownloadExceedAllowance(oldAllocations, originalSize);
	console.log("Will Exceed: " + exceed);
	if (exceed) {
		showAlert("Cannot download allocation data. Data would exceed Quota.", {type: "error", callback: function() {
			callback(false);
		}});
	} else {
		setAllocations(oldAllocations);
		callback(true);
	}
}

/**
 * @description Takes two sets of casks, and merges the scanStatus where the cask matches by barcode
 * @param oldAllocationCasks The original set of casks
 * @param newAllocationCasks The new set of casks received from the server
 * @returns {Object}
 */
function mergeAllocationStatusesByBarcode(oldAllocationCasks,newAllocationCasks) {
  "use strict";
  for(var i=0; i<newAllocationCasks.length;i++) {
    //go through old allocation to find matching barcode
    var index = -1;
    for(var j=0;j<oldAllocationCasks.length;j++) {
      if(oldAllocationCasks[j].barcode === newAllocationCasks[i].barcode) {
        index = j;
        break;
      }
    }
    if(index >= 0) {
      //if found then check for difference in scanStatus, and merge (split, add unique, string)
      var oldStats = (oldAllocationCasks[index].scanStatus !== "")?oldAllocationCasks[index].scanStatus.split(","):[],
          newStats = (newAllocationCasks[i].scanStatus !== "")?newAllocationCasks[i].scanStatus.split(","):[];
			newAllocationCasks[i].scanStatus = arrayUnique(oldStats.concat(newStats)).join(); //TODO
    }
    //if not found then cask is new
    //Ignores oldAllocationCasks not in new list - fine as they have been removed.
  }
  return newAllocationCasks;
}

/**
 * @description Takes two sets of tuns, and merges the scanStatus where the tun properties are matched.
 * @param oldAllocationTuns The original set of tuns found on the allocation on the scanner
 * @param newAllocationTuns The new set of tuns sent by the server
 * @returns {Object}
 */
function mergeAllocationStatusesByTun(oldAllocationTuns, newAllocationTuns) {
	"use strict";
	for(var i=0; i<newAllocationTuns.length;i++) {
		//go through old allocation to find matching barcode
		var index = -1;
		for(var j=0;j<oldAllocationTuns.length;j++) {
			if(oldAllocationTuns[j].warehouse === newAllocationTuns[i].warehouse && oldAllocationTuns[j].tunNumber === newAllocationTuns[i].tunNumber ) {
				index = j;
				break;
			}
		}

		if(index >= 0) {
			//if found then check for difference in scanStatus, and merge (split, add unique, string)
			var oldStats = (oldAllocationTuns[index].scanStatus !== "")?oldAllocationTuns[index].scanStatus.split(","):[],
				newStats = (newAllocationTuns[i].scanStatus !== "")?newAllocationTuns[i].scanStatus.split(","):[];
			newAllocationTuns[i].scanStatus = arrayUnique(oldStats.concat(newStats)).join(); //TODO
		}
	}
	return newAllocationTuns;
}

/**
 * @description Trims the available allocation Array to only those not downloaded.
 * @param {Object} available
 * @returns {Object|Array}
 */
function trimAvailableAllocations(available) {
  "use strict";
  if (available === "") {
    return [];
  }
  var allocations = getAllocations();

  for (var i = available.length - 1; i >= 0; i--) {
    var index = searchListForItem(allocations, "requestNumber", +available[i]);
    if (index !== null) {
      // found as we have an index in the available array
      available.splice(i, 1);
    }
  }
  return available;
}

/**
 * @description Updates the allocations on the scanner if there are any.
 * Will only return callback false if a list of allocations to download exists and the request fails.
 * This will return callback true if the server could not return a list of allocations.
 * @param callback The function run once updates complete
 */
function updateAllocations(callback) {
	"use strict";
	//This fuction is based from dramscan.auth and dsallocation.php.
	var downloadAll = areAllAllocationsDownloaded();

	getAllocationListToDownload(downloadAll, function(stringList) {
		if (stringList === "") {
			// no allocations can be downloaded.
			// If we are downloading all, then this may be bad, though none could be exported.
			// If we just want to update what is on the scanner, then there are no allocations downloaded.
			if (downloadAll) {
				callback(true,404); // The listing function was successful, but there are none exported.
			} else {
				// Not downloading all, but there is no list. That could mean none are on the scanner
				callback(true,404);
			}
		} else {
			// We have a list, let's download those ones.
			//showAjaxLoading();
			downloadMultipleAllocations(stringList, function(success) {
				//hideAjaxLoading();
				if (success) {
					// Allocations were downloaded
					callback(true,200);
				} else {
					// Allocations could not be downloaded
					callback(false,0);
				}
			});
		}
	});
}

/**
 * @description Removes a specified allocation number from the scanner.
 * @param {Number} allocationNumber
 */
function removeSingleAllocation(allocationNumber) {
  "use strict";
  var allocations = getAllocations();

  for (var i = 0; i<allocations.length; i++) {
    if (allocations[i].requestNumber === allocationNumber) {
      // updates once and returns after save.
      allocations.splice(i,1);
      setAllocations(allocations);
      return;
    }
  }
}

/**
 * @description Removes allocations which are no longer available to scanners.
 * @param {Object} available
 */
function removeAllocations(available) {
  "use strict";
  var allocations = [];
  if (available !== "") {
    available = available.split(",");
    allocations = getAllocations();

    for (var i = allocations.length - 1; i >= 0; i--) {

      if (!searchList(String(allocations[i].requestNumber), available)) {
        allocations.splice(i, 1);
      }
    }
  }
	setAllocations(allocations);
}

/**
 * @description Validates an allocation number, attempts to download if not already on the scanner.
 * @param {Number|number} allocationNumber The allocation number
 * @param callback Function to run once validated
 * @returns {*}
 */
function validateAllocation(allocationNumber, callback) {
  "use strict";
  if (!isNumeric(allocationNumber)) return callback(false, null);
  // try to find allocation on scanner,
  var programAllocationList = listProgramAllocationsOnScanner();
  var allocations = getAllocations(),
      index = searchListForItem(allocations, "requestNumber", allocationNumber);

  // now check against list allowed by the current program
  if (!searchList(Number(allocationNumber),programAllocationList) && index !== null) {
    //Could't find the allocation in the list for the program but was found on scanner
    showAlert("Invalid allocation type",{canClose:true, focus:"allocation"});
    // If the screen has a confirm button now disabled we need to clear that attribute.
    // Normally each screen does that on error of callback but it needs to be here as there is no callback.
    $('.'+'disabledAllocationConfirm').removeAttr("disabled").removeClass('disabledAllocationConfirm');
    return; // return prevents any callback functions running. This is safer to allow a message here.
  }

  if (index === null) {
    // Caveat if this is for a program with set type of allocations to use
    // as this will download an allocation regardless of type. we may not want the download.
    // download callback checks for successful download.
    downloadAllocation(allocationNumber, function (successful) {

      if (successful) {
        // before checking to save we check the allocation type against what the program allows.
        // Reload the program Allocation List now, as the download has completed and saved the allocation to storage.
        programAllocationList = listProgramAllocationsOnScanner();
        // now check against list allowed by the current program
        if (!searchList(Number(allocationNumber),programAllocationList) && index !== null) {
          //Could't find the allocation in the list for the program
          showAlert("Invalid allocation type",{canClose:true, focus:"allocation"});
          // Since we just downloaded the wrong type, we can safely remove it to save clogging up space.
          removeSingleAllocation(allocationNumber);
          // If the screen has a confirm button now disabled we need to clear that attribute.
          // Normally each screen does that on error of callback but it needs to be here as there is no callback.
          $('.'+'disabledAllocationConfirm').removeAttr("disabled").removeClass('disabledAllocationConfirm');
          return; // return prevents any callback functions running. This is safer to allow a message here.
        }

        // now check the local storage again
        allocations = getAllocations();
        var index = searchListForItem(allocations, "requestNumber", allocationNumber);
        if (index !== null) {
          savePickingAllocation(index, allocations[index]);
          return callback(true, index);
        } else {
          return callback(false, null);
        }
      } else {
        return callback(false, null);
      }
    });
  } else {
    // even if we have found the allocation we should update if possible.
    downloadAllocation(allocations[index].requestNumber, function (successful, HTTPCode) {
      if (successful) {
        allocations = getAllocations();
      }

      if (HTTPCode === 404) {
        // The update has returned 404. We are validating existing data so we remove that allocation here.
        removeSingleAllocation(allocations[index].requestNumber);
        showAlert('This allocation is no longer available', {focus:"allocation"});
        // problem on page where the allocation is still in the picker. We need to refresh that dropdown if we can.
        if (allocationSelectElement !== "") {
          createAllocationSelectOptions(allocationSelectElement); // Used the cached element name to recreate the list.
        }
        // we do nothing at this point. No callback should fire, as the above message will be displayed.
      } else {
        // the request may not have been successful but we can continue offline.
        savePickingAllocation(index, allocations[index]);
        return callback(true, index);
      }
    });
  }
}

/**
 * @description Checks the input disgorge line against the picking allocation, and sets it if
 * @param {String} lineNumber - The disgorge line the user wants to use.
 * @param {String} focusElementId - The element to refocus on if not valid.
 * @returns True if the line is valid and has been set. False otherwise.
 */
function validateDisgorgeLine(lineNumber, focusElementId) {
  "use strict";
  var disgorgeLines = pickingAllocation.disgorgeLines.split(","),
      allocationUpdateTime = new Date(pickingAllocation.updateTime),
      now = new Date();

    if (lineNumber === "") {
        showAlert("Line number cannot be blank.",{focus:"line"});
        return false;
    }

    // If the allocation updated more than 5 seconds ago, we don't have network.
    if(now-allocationUpdateTime > 5000) {
        showAlert("Line check unavailable");
        return false;
    }

    // The allocation is up to date, so see if we can find the line assigned.
    if(searchList(lineNumber,disgorgeLines)) {
        //todo could be held in picking, not LS (More complicated to change than it sounds).
        localStorage.setVariable("disgorgeline", lineNumber, false);
    } else {
        // not valid line
        showAlert("Invalid line entered for the allocation.",{focus:focusElementId});
        return false;
    }
    return true;
}

/**
 * @description Saves the allocation details into Global Variables for scanning through the allocation.
 * @param {Number} index Position in the allocation array which the specific allocation is held.
 * @param {Object} allocation The allocation object to set in memory
 */
function savePickingAllocation(index, allocation) {
  "use strict";
  pickingAllocation.location =  getScannerLocationCode();
  pickingAllocation.number = allocation.requestNumber;
  pickingAllocation.type = allocation.allocationType;
  pickingAllocation.index = index;
  pickingAllocation.age = allocation.minimumAge;
  pickingAllocation.disgorgeLines = allocation.disgorgeLines;
  pickingAllocation.updateTime = allocation.updateTime;
  pickingAllocation.casks = (allocation.casks) ? allocation.casks : [];
  pickingAllocation.tuns = (allocation.tuns) ? allocation.tuns : [];
  pickingAllocation.pallets = (allocation.pallets) ? allocation.pallets: [];
  pickingAllocation.scanned = countScannedCasks();
	pickingAllocation.scannedTuns = countScannedTuns();
  pickingAllocation.version = allocation.requestVersion;
  pickingAllocation.hasOrderLines = ((allocation.orderLines || []).length > 0);
  pickingAllocation.orderLines = allocation.orderLines || [];
  pickingAllocation.operationType = allocation.operationType || ""; // Or for previous downloads on existing installs.
  pickingAllocation.casksPerPallet = allocation.casksPerPallet || 0; // Or for previous downloads on existing installs.
}

/**
 * @description A custom function, called only from dsvan.php. This sets the van and seal numbers against the picking allocation.
 * @param {* | String | Number} vanNumber
 * @param {* | String | Number} firstSeal
 * @param {* | String | Number} secondSeal
 * @param {* | String | Number} thirdSeal
 */
function saveVanDetails(vanNumber, firstSeal, secondSeal, thirdSeal) {
	"use strict";
	pickingAllocation.van = vanNumber;
	pickingAllocation.seal1 = firstSeal;
	pickingAllocation.seal2 = secondSeal;
	pickingAllocation.seal3 = thirdSeal;
}

/**
 * @description Updates the picking allocation after an update. Does not update UI elements.
 */
function updatePickingAllocationNoUi() {
	"use strict";
	var allocations = getAllocations();
	var index = searchListForItem(allocations, "requestNumber", pickingAllocation.number);
	if (index !== null) {
		savePickingAllocation(index, allocations[index]);
		return true;
	} else {
		return false;
	}
}

/**
 * @description Builds option elements to be added to a dropdown list on pages where an allocation can be selected.
 * @param {String} elementSelector The select element to be updated, and including the '#' for selector type.
 */
function createAllocationSelectOptions(elementSelector) {
  "use strict";
  // Add allocations to dropdown
  var scannerAllocations = listProgramAllocationsOnScanner();
  // clear the element list, and save its name for refreshing if the page needs to.
  allocationSelectElement = elementSelector; // This will allow us to refresh the element later. There is only ever one per page so caching it is no problem.
  // Reset the selector to have the standard control options.
  $(elementSelector).html('<option selected value="-1" disabled>Choose an allocation</option>\n' +
    '                      <option value="0">Key in manually</option>');
  for(var i=0; i<scannerAllocations.length; i++) {
    $(elementSelector).append('<option value="'+scannerAllocations[i]+'">'+scannerAllocations[i]+'</option>');
  }
  // Hide the default element name for manual entry of an allocation just in case it has been displayed.
  $("#allocationEntryGroup").hide();
  // Select the default option to be sure the current value is cleared.
  $(elementSelector).val("-1");
  // If the screen has a confirm button now disabled we need to clear that attribute.
  // Normally each screen does that on error of callback but when this function runs from a removal of an allocation
  // or from downloading it incorrectly it needs to be run elsewhere as there is no callback.
  $('.'+'disabledAllocationConfirm').removeAttr("disabled").removeClass('disabledAllocationConfirm');

}

/**
 * @description Used by version check and check allocation, if a change is found then the picking data is updated, along with the UI.
 */
function updatePickingAllocation() {
  "use strict";
  if(updatePickingAllocationNoUi()) {
    // this is only done if the above call finds the allocation to update in downloaded data.
    // calculate cask requirement
    var casksRequired = (pickingAllocation.hasOrderLines) ? countTotalRequiredCasks(pickingAllocation.orderLines, pickingAllocation.casks): pickingAllocation.casks.length;
    $("#counterlabel").html(pickingAllocation.scanned + '/' + casksRequired); //updates the counter on screen
		$("#allocationTotal").html(pickingAllocation.scanned + '/' + casksRequired); // Updates the counter on the template screen
    $("#allocationVersion").html(pickingAllocation.version);
  }
}

/**
 * @description Updates the allocation counter on the page.
 */
function updateAllocationCounter() {
  "use strict";
  // a scan has taken place, update the counter on the page.
  var casksRequired = (pickingAllocation.hasOrderLines) ? countTotalRequiredCasks(pickingAllocation.orderLines, pickingAllocation.casks): pickingAllocation.casks.length;
  $("#counterlabel").html(pickingAllocation.scanned + '/' + casksRequired);
  if (pickingAllocation.scanned === pickingAllocation.casks.length) showAlert("Allocation completed.", {type: "info"});
}

/**
 * @description Updates the allocation counter on the page. This works with new template pages.
 */
function updateTemplateAllocationCounter() {
	"use strict";
	// a scan has taken place, update the counter on the page.
	var casksRequired = (pickingAllocation.hasOrderLines) ? countTotalRequiredCasks(pickingAllocation.orderLines, pickingAllocation.casks): pickingAllocation.casks.length;
	$("#allocationTotal").html(pickingAllocation.scanned + '/' + casksRequired);
	if (pickingAllocation.scanned === pickingAllocation.casks.length) showAlert("Allocation completed.", {type: "info"});
}

function updateTunAllocationCounter() {
	"use strict";
	$("#allocationTotal").html(pickingAllocation.scannedTuns +"/"+pickingAllocation.tuns.length);
	if (pickingAllocation.scannedTuns === pickingAllocation.tuns.length) showAlert("Allocation completed.", {type: "info"});
}

/**
 * @description Work out what validation field to filter on, and filter an allocated cask array by that field.
 * The allocatedCaskArray must be of type allocationCask objects as define in this file.
 * @param {barcodeObject} barcodeObject
 * @param {Array<allocationCask>} allocatedCaskArray
 */
function filterAllocatedCaskArray(barcodeObject, allocatedCaskArray) {
    "use strict";
    var validationLength, mapping, validationFunction, doubleScanCounter;

    // todo change for tuns as a barcode type

    switch (barcodeObject.vesselBarcodeType) {
        case VesselBarcodeType.scotch:
            validationFunction = function(el, index) {
                el.pickingAllocationCaskIndex = index; // Add property to map the element back to the picking allocation casks.
              return (doesSWABarcodesMatch(barcodeObject.barcode, el.barcode));
            };
            break;
        case VesselBarcodeType.pallet:
            validationFunction = function(el, index) {
                el.pickingAllocationCaskIndex = index; // Add property to map the element back to the picking allocation casks.
                return barcodeObject.pallet.palletNo === el.palletNo;
            };
            break;
      default:

            // todo should this even be using a length and substring. In double scan checks, it is simply matched.
            // Note that if a customID is used and there is no woodTrackingMap, then we simply take the length of the caskId as a baseline
            validationLength = localStorage.getItem("settings/woodTrackingMap") ? localStorage.getItem("settings/woodTrackingMap").length : barcodeObject.caskId.length;
            validationFunction = function(el, index) {
                el.pickingAllocationCaskIndex = index; // Add property to map the element back to the picking allocation casks.
                return barcodeObject.caskId.substr(0,validationLength) === el.caskId.substr(0,validationLength);
            };
            break;
    }

    return allocatedCaskArray.filter(validationFunction);
}

/**
 * @description Wrapper function. Validates the barcode scanned against the allocation, returning any error.
 * @param barcodeObject
 * @returns {*} Error object if an error found, otherwise null.
 */
function isCaskValidInAllocation(barcodeObject) {
  "use strict";
  var foundCasks, doubleScanCounter, checkData = "", loopError = null,
      loopErrorCount = 0, program = getProgramTransactionCode().toUpperCase();

  // Filter the casks based on the validation function created on type.
  foundCasks = filterAllocatedCaskArray(barcodeObject, pickingAllocation.casks);

  if (foundCasks.length < 1) {
      //1. if not found not in allocation (no matches were filtered).
      return raiseSystemIncident("notOnAllocation", getProgramTransactionCode().toUpperCase() !== "DISGORGE", "");
  }

  // If we scanned a pallet we need to check the length of matched casks is the same as the qty of casks on the pallet.
  if (barcodeObject.vesselBarcodeType === VesselBarcodeType.pallet) {
      var pallet = pickingAllocation.pallets.filter(function(el) {
          return el.palletNo === barcodeObject.pallet.palletNo;
      });
      if (pallet.length > 0) {
          // The pallet was found;
          if (pallet[0].caskCount > foundCasks.length) {
              // There are casks on the pallet that are not on the allocation.
              return raiseSystemIncident("notOnPallet", true, "");
          }
      }
  }

  // loop through the result casks again to check each of them. Usually one but pallet may have more.
  // from here we also need to see how many errors there are.
  // we need to set a loop error to the first problem we find.
  // Stopping will occur if we scanned a pallet for the two age checks.
  var stopErrors = (barcodeObject.vesselBarcodeType === VesselBarcodeType.pallet);
  foundCasks.forEach(function(caskFound) {

      // Set checkData based on the current caskFound. As this is allocations we have both parts.
      // If the caskID is not blank then we use it unless useSWA is set.
      if (caskFound.caskId === "" || localStorage.getItem("settings/useSWA") === "true") {
          checkData = caskFound.barcode; // Note that this wont work if we use SWA fields to validate a barcode.
      } else {
          checkData = caskFound.caskId;
      }

      // The following two must stop the program if a pallet was scanned. The transaction will error.
      // Each cask would need to be scanned individually after.
      // This is to identify error cask correctly with error transaction.
      //3. Is the cask legally old enough?
      if(!isCaskLegallyOldEnough(caskFound)) {
          if (!loopError) loopError = {errorName: "belowLegalAge", stopProgram: false, checkData: checkData};
          loopErrorCount ++;
      }
      //4. Is the cask old enough to be in the allocation
      if(!isCaskOldEnoughForAllocation(caskFound)) {
          if (!loopError) loopError = {errorName: "belowBlendAge", stopProgram: false, checkData: checkData};
          loopErrorCount ++;
      }

      //5. Check order lines and that having casks scanned will be okay.
      if (pickingAllocation.hasOrderLines) {
          //Check the state of the order line
          if (!checkOrderLine(caskFound)) {
              if (!loopError) loopError = {errorName: "orderLineFull", stopProgram: true, checkData: checkData};
              loopErrorCount ++;
          }
      }
  });

  // If we have a loop error then we must check the program stops.
  // We also need to check if the error was within a pallet. If so raise a pallet error instead.
  // We only need the pallet error if there were several problems. Otherwise directly show what caused the pallet to error.
  // The returns must be done outwith loops to prevent multiple returns.
    if (loopError && loopErrorCount > 1 && barcodeObject.vesselBarcodeType === VesselBarcodeType.pallet) {
        return raiseSystemIncident("palletError", true, "");
    } else if (loopError && loopError.stopProgram) {
        return raiseSystemIncident(loopError.errorName, loopError.stopProgram, loopError.checkData);
        // The program will stop so dont continue, and dont update the counters. retained functionality.
    }

  // to avoid multi scans with a mix of pallets and casks we need to count double scans. Do this in a separate loop.
  doubleScanCounter = 0;
  foundCasks.forEach(function(caskFound) {
    //2. Has it been previously scanned somehow?
    if(isScannedInProgram(caskFound)) {
      doubleScanCounter ++;
    }
  });

  // Note that we check for double scans last. This ensures that a more serious message gets priority.
  // All other messages may signify greater importance for disgorge for example where a double scan
  // is not so much a problem with the cask on the allocation.
  if (doubleScanCounter > 0) {
    // There was at least one double scan. Show the correct incident.
    var item = (barcodeObject.vesselBarcodeType === VesselBarcodeType.pallet) ? "pallet" : getLabels().caskLabel;
    // DoubleScanCounter = n and results = n (Item already fully scanned)
    if (doubleScanCounter === foundCasks.length) {
      return raiseSystemIncident("scanned", true, item);
    }
    // else would end up as: doubleScanCounter < results (some items already scanned)
    // we could throw an error here but it seems a bit pointless. Just allow the scan of the rest of the items.
  }

  // Because we have a reference to the barcode object and we have allocation info we can add more information.
  if (barcodeObject.vesselBarcodeType === VesselBarcodeType.caskId) {
      // Find the SWA information for the barcode and SWA object.
      // barcode is sent to the server, may not be needed. SWA is used to display more information on the scanner.
      barcodeObject.barcode = getBarcodeFromBarrelId(barcodeObject.caskId);
      barcodeObject.swa = barcodeToData(barcodeObject.barcode);
  }

  // At this point all casks matches must have passed validation. Increment the picking counter
    pickingAllocation.scanned += foundCasks.length;
  // Update all of the casks now found (may be many on a pallet) with new status.
  foundCasks.forEach(function(el) {
      var i = el.pickingAllocationCaskIndex;
     pickingAllocation.casks[i].scanStatus = ((pickingAllocation.casks[i].scanStatus === "") ? "" : ",") + program;
  });
  // now we have updated in memory we also want to persist the changes to local storage
  persistPickingCasks();
  if (loopError) {
      // if we return the loop error here, it was due to allowing the program to continue.
      return raiseSystemIncident(loopError.errorName, loopError.stopProgram, loopError.checkData);
  }
  return null; // Default is no errors and therefore no errors to return.
}

function isTunValidInAllocation(warehouse, tunNumber) {
	"use strict";
	var program = getProgramTransactionCode().toUpperCase(),
		foundTunIndex = -1;

	//Search the picking allocation for the tun
	for (var i=0; i<pickingAllocation.tuns.length; i++) {
		var el = pickingAllocation.tuns[i];
		if (el.warehouse === warehouse && el.tunNumber === tunNumber) {
			foundTunIndex = i;
			break;
		}
	}

	//1. If not found in the allocation
	if (foundTunIndex < 0) {
		return raiseSystemIncident("notOnAllocation", getProgramTransactionCode().toUpperCase() !== "TUNDUMP", "");
	}

	//2. Has it been previously scanned?
	if(isTunScannedInProgram(foundTunIndex)) {
		return raiseSystemIncident("scanned", true, "");
	}

    // everything was valid, update the counter
    pickingAllocation.scannedTuns ++;
	pickingAllocation.tuns[foundTunIndex].scanStatus = ((pickingAllocation.tuns[foundTunIndex].scanStatus === "") ? "" : ",") + program;
	// Persist the new status to storage.
	persistTunStatus(foundTunIndex, pickingAllocation.tuns[foundTunIndex].scanStatus);
  return null;
}

/**
 * @description Checks if the cask has been scanned in the program - based on the cask scan status
 * @param {Object} cask from Picking Casks.
 * @returns {Array|{index: number, input: string}}
 */
function isScannedInProgram(cask) {
  "use strict";
  var program = getProgramTransactionCode().toUpperCase(),
      status = cask.scanStatus.toUpperCase();
  return status.match(new RegExp(program, "i"));
}

function isTunScannedInProgram(index) {
	"use strict";
	var program = getProgramTransactionCode().toUpperCase(),
		status = pickingAllocation.tuns[index].scanStatus.toUpperCase();
	return status.match(new RegExp(program, "i"));
}

/**
 * @description Checks if the cask is legally old enough based on today's date.
 * @param {Object} cask The cask in the allocation found.
 * @returns {boolean} True if old enough
 */
function isCaskLegallyOldEnough(cask) {
  "use strict";
  var dateToday = new Date(),
      caskDate = new Date(cask.age),
      difference = Math.abs(dateToday - caskDate),
      legalAge = cask.legalMinimum * 31536000000; // years in ms
  return (difference >= legalAge);
}

/**
 * @description checks to see that the cask is old enough for the allocations blend minimum age
 * @param {Object} cask the cask from picking allocation
 * @return {Boolean} true if the cask is old enough, otherwise false.
 * @property {string} age
 */
function isCaskOldEnoughForAllocation(cask) {
  "use strict";
  var todayDate = new Date(),
      caskDate = new Date(cask.age),
      difference = Math.abs(todayDate - caskDate),
      allocationAge = parseInt(pickingAllocation.age) * 31536000000; // years in ms
  return (difference >= allocationAge);
}

/**
 * @description Counts casks scanned from the allocation within the current program / Transaction code.
 * @returns {Number}
 */
function countScannedCasks() {
  "use strict";
  var program = getProgramTransactionCode().toUpperCase();
  var allocationCasks = pickingAllocation.casks.filter(function (el) {
    return el.scanStatus.toUpperCase().indexOf(program) !== -1;
  });
  return allocationCasks.length;
}

/**
 * @description Counts tuns scanned from the allocation with the current Programs transaction code
 * @returns {Number}
 */
function countScannedTuns() {
	"use strict";
	var program = getProgramTransactionCode().toUpperCase();
	var allocationTuns = pickingAllocation.tuns.filter(function (el) {
		return el.scanStatus.toUpperCase().indexOf(program) !== -1;
	});
	return allocationTuns.length;
}

/**
 * @description Counts the number of labelled casks against casks in the batch
 * @param {String} batchId The batch ID to count
 * @returns {{labelled: Number, total: Number}} An object with count of labelled casks in the batch and total in batch.
 */
function countBatchCasks(batchId) {
  "use strict";
  var mapping = getBarcodeMapping();
  var batchCasks = pickingAllocation.casks.filter(function (el) {
    return el.barcode.substr(mapping.batch.start, mapping.batch.length).toUpperCase() === batchId.toUpperCase();
  });

  var prog = getProgramTransactionCode().toUpperCase();
  var labelledCasks = batchCasks.filter(function (el) {
    return el.scanStatus.toUpperCase().indexOf(prog) !== -1;
  });

  return {
    labelled: labelledCasks.length,
    total: batchCasks.length
  };
}

/**
 * @description Looks up all of the casks in an allocation, to find the batches.
 * @returns {Array} An array of batch Ids
 */
function getAllocationBatches() {
  "use strict";
  var batches = [],
      mapping = getBarcodeMapping();

  for(var i = 0; i < pickingAllocation.casks.length; i++) {
    if(!searchList(pickingAllocation.casks[i].barcode.substr(mapping.batch.start, mapping.batch.length).toUpperCase(),batches)) {
      batches.push(pickingAllocation.casks[i].barcode.substr(mapping.batch.start, mapping.batch.length).toUpperCase());
    }
  }
  return batches;
}

/**
 * @description Finds the first picking cask which matches the batch Id
 * @param {string} batchId
 * @returns {number} The index at which the first picking cask with batch Id is found, -1 if not found.
 */
function findFirstCaskInBatch(batchId) {
  "use strict";
  var mapping = getBarcodeMapping();
  for (var i = 0; i < pickingAllocation.casks.length; i++) {
    if ((pickingAllocation.casks[i].barcode.substr(mapping.batch.start, mapping.batch.length).toUpperCase() === batchId.toUpperCase()) && pickingAllocation.casks[i].caskId === "") {
      return i;
    }
  }
  return -1;
}

/**
 * @description Get the remaining casks to be scanned, based on the picking allocation type.
 * Allows exclusion of un-scanned casks in an order line when the order line has required casks
 * @returns {Array} The remaining un-scanned casks
 */
function getRemainingCasksByAllocationType() {
  "use strict";
  return pickingAllocation.hasOrderLines ? getAllRemainingOrderLineCasks() : getRemainingCasks();
}

/**
 * @description Count total casks required by an allocation and its order lines. Includes Allocated stock un-scanned.
 * @param {Array} orderLines An array of order lines
 * @param {Array} casks An array of all the casks to see how many there are unallocated
 * @returns {number} The total required
 */
function countTotalRequiredCasks(orderLines, casks) {
  "use strict";
  var total = 0;
  for (var i=0; i<orderLines.length; i++) {
    total += (orderLines[i].casksRequired - orderLines[i].casksScanned); //deduct scanned from total as scanned below
  }
  //Included allocated casks (this includes what was once on order line - the above math deducts those accordingly)
  var allocatedCasks =  casks.filter(function (el) {
    return el.orderLine === 0;
  });
  total += allocatedCasks.length;
  return total;
}

/**
 * @description Static method. Count total casks required by an allocation and its order lines.
 * @param {Array} orderLines An array of order lines
 * @returns {number} The total required
 */
function countTotalRequiredCasksWithoutAllocatedStock(orderLines) {
  "use strict";
  var total = 0;
  for (var i=0; i<orderLines.length; i++) {
    total += orderLines[i].casksRequired;
  }
  return total;
}

/**
 * @description Fetches all casks possible to be scanned in the order line allocation.
 * If an order line has met its cask requirement then none of its possible remaining casks are returned.
 * @returns {Array} remainingCasks
 */
function getAllRemainingOrderLineCasks() {
  "use strict";
  var orderLines = pickingAllocation.orderLines;
  var remainingCasks = [];
  for (var i=0; i<orderLines.length; i++) {
    var scannedOnOrderLine = countScannedOrderLineCasks(orderLines[i]);
    var requiredOnOrderLine = orderLines[i].casksRequired;
    if (scannedOnOrderLine < requiredOnOrderLine) {
      // order line incomplete - get available on order line
      remainingCasks = remainingCasks.concat(getRemainingOrderLineCasks(orderLines[i].orderLineNumber));
    }
  }
  // Also include casks which were allocated but not yet scanned
  remainingCasks = remainingCasks.concat(getRemainingOrderLineCasks(0));

  return remainingCasks;
}

/**
 * @description Fetches all casks available to be scanned against a given order line
 * @param {Number} orderLineNumber
 * @returns {Array} Remaining casks
 */
function getRemainingOrderLineCasks(orderLineNumber) {
  "use strict";
  var program = getProgramTransactionCode().toUpperCase();
  return pickingAllocation.casks.filter(function (el) {
    return (el.scanStatus.toUpperCase().indexOf(program) === -1) && (el.orderLine === orderLineNumber);
  });
}

/**
 * @description Similar to countScannedCasks, and additionally refines by an order line number
 * @param {Object} orderLine The order line we are counting. Count locals scans + scans made before download.
 * @returns {Number}
 */
function countScannedOrderLineCasks(orderLine) {
  "use strict";
  var program = getProgramTransactionCode().toUpperCase();
  var allocationCasks = pickingAllocation.casks.filter(function (el) {
    return (el.scanStatus.toUpperCase().indexOf(program) !== -1) && (el.orderLine === orderLine.orderLineNumber);
  });
  return (allocationCasks.length + orderLine.casksScanned);
}

/**
 * @description Finds an order line from an array of order lines
 * @param {Number} orderLineNumber
 * @param {Array} orderLines
 * @returns {*|{}}
 */
function findOrderLine(orderLineNumber, orderLines) {
  "use strict";
  return orderLines[searchListForItem(orderLines,"orderLineNumber",orderLineNumber)];
}

/**
 * @description Check the cask scanned against picking order lines. If the order line on the cask is 0 then ignore
 * @param {Object} foundCask The cask found on the picking allocation.
 * @returns {boolean}
 */
function checkOrderLine(foundCask) {
  "use strict";
  var pass = false;
  if (foundCask.orderLine === 0) {
    pass = true;
  } else {
    var orderLine = findOrderLine(foundCask.orderLine,pickingAllocation.orderLines);
    // If scans against order line is still less than required then its a pass
    if(countScannedOrderLineCasks(orderLine) < orderLine.casksRequired) {
      pass = true;
    }
  }
  return pass;
}

/**
 * @description Finds a given cask ID in the current picking allocation.
 * @param {String} caskId
 * @returns {number} Returns -1 if not found.
 */
function findCaskInAllocation(caskId) {
  "use strict";
  for (var i = 0; i < pickingAllocation.casks.length; i++) {
    if (pickingAllocation.casks[i].caskId === caskId) {
      return i;
    }
  }
  return -1;
}

//todo the below should be deprecated once allocation casks have their spirit details.
/**
 * @description Finds the barcode of the given Cask Id in the current picking allocation
 * @param barrelId
 * @returns {string}
 */
function getBarcodeFromBarrelId(barrelId) {
  "use strict";
  for (var i = 0; i < pickingAllocation.casks.length; i++) {
    if ((pickingAllocation.casks[i].caskId.toUpperCase() === barrelId.toUpperCase()) && barrelId !== "") {
      return pickingAllocation.casks[i].barcode;
    }
  }
}

// todo the below should directly use SWA info from the allocation cask.
/**
 * @description Finds the barcode information of the given cask Id. Then returns the SWA data.
 * @param barrelId
 * @returns {{}}
 */
function getCaskDataFromBarrelId(barrelId) {
	"use strict";
	return barcodeToData(getBarcodeFromBarrelId(barrelId));
}

/**
 * @description NOT used yet, would need to be hooked in to the line selection when scanning allocations
 * AND most importantly, the transaction save would need to use getDisgorgeLine first.
 * @param {string} disgorgeLine
 */
function setDisgorgeLine(disgorgeLine) {
	"use strict";
	pickingAllocation.disgorgeLine = disgorgeLine;
}

/**
 * @description Get the disgorge line for the transaction / current scanning program TODO implement in transaction.js
 * @returns {string} The current disgorge line
 */
function getDisgorgeLine() {
	"use strict";
	if(localStorage.getItem("disgorgeline")) return localStorage.getItem("disgorgeline");
	else return pickingAllocation.disgorgeLine;
}

/**
 * @description Given a pallet number, returns an array of all the casks allocated and on that pallet.
 * @param {Number} palletNo
 * @returns {Array<allocationCask>}
 */
function getCasksOnPallet(palletNo) {
    "use strict";
    return pickingAllocation.casks.filter(function(el, index) {
        el.pickingAllocationCaskIndex = index; // Add property to map the element back to the picking allocation casks.
        return palletNo*1 === el.palletNo;
    });
}

/**
 * @description Finds the remaining casks to be scanned in the current program.
 * @returns {Array} Array of casks.
 */
function getRemainingCasks() {
  "use strict";
  var program = getProgramTransactionCode().toUpperCase();
  return pickingAllocation.casks.filter(function (el) {
    return el.scanStatus.toUpperCase().indexOf(program) === -1; // if disgorge count for RegExp "disgorge", same for despatch. match will be "" if not matched prog
  });
}

/**
 * @description Saves changes to the picking casks array back to local storage. Usually this is just statuses.
 */
function persistPickingCasks() {
    "use strict";
    var allocations = getAllocations();
    allocations[pickingAllocation.index].casks = pickingAllocation.casks;
    setAllocations(allocations);
}

/**
 * @description [DEPRECATED] Saves changes to a picking casks status to the allocation in LS.
 * @param {Number} caskIndex The position in the array of casks for the allocation, previously found.
 * @param {String} newStatus The new status of the cask.
 */
function persistCaskStatus(caskIndex, newStatus) {
  "use strict";
  var allocations = getAllocations();
  allocations[pickingAllocation.index].casks[caskIndex].scanStatus = newStatus;
	setAllocations(allocations);
}

function persistTunStatus(tunIndex, newStatus) {
	"use strict";
	var allocations = getAllocations();
	allocations[pickingAllocation.index].tuns[tunIndex].scanStatus = newStatus;
	setAllocations(allocations);
}

/**
 * @description Saves changes to a picking cask to the allocation in LS
 * @param {Number} caskIndex The position in the array of casks for the allocation, previously found.
 */
function persistPickingCask(caskIndex) {
  "use strict";
  var allocations = getAllocations();
  allocations[pickingAllocation.index].casks[caskIndex] = pickingAllocation.casks[caskIndex];
	setAllocations(allocations);
}

/**
 * @typedef {object} allocation An Allocation Object
 * @property {Number} requestNumber Allocation number
 * @property {String} allocationType The type of allocation, CASK, ORDERLINE, RECEIPT, or DESPATCH
 * @property {String} blend Blend description
 * @property {Number} requestVersion Version of allocation, updated by changes to parcels
 * @property {Number} minimumAge Minimum age a cask should be on the allocation
 * @property {String} location Location the allocation is for
 * @property {String} disgorgeLines A comma list of disgorge lines the allocation has been assigned to
 * @property {String} freeDisgorgeLines A comma list of disgorge lines available to be assigned to
 * @property {Number} errors Number of errors occurred scanning against the allocation
 * @property {Array} [orderLines] Array of Order Lines if the allocation is order line
 * @property {Array} [casks] Array of allocated casks for a standard allocation
 * @property {Array} [tuns] Array of allocated tuns for a tun allocation
 * @property {Array} [pallets] Array of pallets that are used in an allocation when real pallets are in use.
 * @property {String} operationType - A,B, or C for Filling, Filling and Dumping, or just Dumping.
 * @property {Number} casksPerPallet - For Drain and fill operations, allocation specifies the pallet size.
 *
 * @typedef {Object} orderLine A blending order line used in allocations
 * @property {Number} orderLineNumber Unique order line number on the allocation
 * @property {String} location The location the order line is for
 * @property {String} warehouse The warehouse for the order line stock
 * @property {String} bay The bay for the order line stock
 * @property {String} spiritMake The make of the spirit on the order line
 * @property {Number} casksRequired The no of casks needed to fill the order line.
 * @property {Number} casksScanned The no of casks scanned for the order line.
 * @property {Number} age The order line age of spirit
 *
 * @typedef {Object} allocationCask Casks assigned to an allocation or on an order line
 * @property {Number} orderLine Unique order line number on the allocation if applicable
 * @property {String} barcode Unique barcode for the cask
 * @property {String} caskId Unique ID for the cask
 * @property {number} age The age of the casks spirit
 * @property {string} [location] Location of the cask
 * @property {String} [warehouse] Warehouse the cask is in
 * @property {String} rack Rack the cask is stowed in
 * @property {String} level Level the cask is stowed in
 * @property {Number} legalMinimum Minimum age the cask should be
 * @property {String} scanStatus Comma list of transaction codes the cask has been scanned against
 * @property {String} scanError Errors scanned against the cask
 *
 * @typedef {Object} allocationPallet Pallets used in an allocation
 * @property {Number} palletNo
 * @property {Number} caskCount
 **/
